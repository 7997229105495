// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-js-templates-404-js": () => import("./../../../src/js/templates/404.js" /* webpackChunkName: "component---src-js-templates-404-js" */),
  "component---src-js-templates-category-js": () => import("./../../../src/js/templates/category.js" /* webpackChunkName: "component---src-js-templates-category-js" */),
  "component---src-js-templates-guides-js": () => import("./../../../src/js/templates/guides.js" /* webpackChunkName: "component---src-js-templates-guides-js" */),
  "component---src-js-templates-page-js": () => import("./../../../src/js/templates/page.js" /* webpackChunkName: "component---src-js-templates-page-js" */),
  "component---src-js-templates-pages-blog-template-js": () => import("./../../../src/js/templates/pages/blog-template.js" /* webpackChunkName: "component---src-js-templates-pages-blog-template-js" */),
  "component---src-js-templates-pages-bounties-template-js": () => import("./../../../src/js/templates/pages/bounties-template.js" /* webpackChunkName: "component---src-js-templates-pages-bounties-template-js" */),
  "component---src-js-templates-pages-homepage-template-js": () => import("./../../../src/js/templates/pages/homepage-template.js" /* webpackChunkName: "component---src-js-templates-pages-homepage-template-js" */),
  "component---src-js-templates-pages-search-template-js": () => import("./../../../src/js/templates/pages/search-template.js" /* webpackChunkName: "component---src-js-templates-pages-search-template-js" */),
  "component---src-js-templates-pages-tips-child-template-js": () => import("./../../../src/js/templates/pages/tips-child-template.js" /* webpackChunkName: "component---src-js-templates-pages-tips-child-template-js" */),
  "component---src-js-templates-pages-tips-special-template-js": () => import("./../../../src/js/templates/pages/tips-special-template.js" /* webpackChunkName: "component---src-js-templates-pages-tips-special-template-js" */),
  "component---src-js-templates-pages-tips-template-js": () => import("./../../../src/js/templates/pages/tips-template.js" /* webpackChunkName: "component---src-js-templates-pages-tips-template-js" */),
  "component---src-js-templates-post-amp-js": () => import("./../../../src/js/templates/post.amp.js" /* webpackChunkName: "component---src-js-templates-post-amp-js" */),
  "component---src-js-templates-post-js": () => import("./../../../src/js/templates/post.js" /* webpackChunkName: "component---src-js-templates-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

